<template>
  <!-- SIMPLE PRODUIT 1:1 -->
  <div>

    <div v-if="adz && client && adz.type !== 'stories'" class="facebook-post" :class="{ 'is-commenting': commentingAdz && commentingAdz.id !== adz.id }">
      <header class="facebook-post-header">
        <div class="header-markers-box-wrap" @click="handleCreateHeaderMarker($event)">
          <div class="facebook-client">
            <client-logo v-if="client" :client="client" class-name="is-small"></client-logo>
            <div class="facebook-client-name">
              <p class="name">{{ client.name }}</p>
              <div class="sponsor">
                <p>Sponsored</p>
                <img src="@/assets/img/facebook/world.png" alt="Adz" />
              </div>
            </div>

            <div
              v-if="showApprove"
              class="approbation-bt"
              :class="{
                active: adz.isApproved,
              }"
              @click.stop="approveAdz(adz)"
            >
              <p>Approved</p>
              <div class="round">
                <svg width="10" height="8" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.0953 0L12.143 1.00802C10.0036 3.27701 7.73915 5.83967 5.60223 8.14579L2.74509 5.65537L1.70868 4.75112L0 6.95245L1.03641 7.85671L4.84593 11.1773L5.79132 12L6.65267 11.0809C9.07037 8.5165 11.685 5.52279 14.0476 3.01672L15 2.0087L13.0953 0Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>

          <p v-if="adz.content.text == ''" class="post-description">Texte</p>
          <p v-else-if="adz.content.text && commentsToggle" class="post-description">{{ adz.content.text.trim() }}</p>
          <p v-else-if="adz.content.text && !commentsToggle" class="post-description">
            <truncate clamp="See More" :length="126" less="Show Less" type="html" :text="adz.content.text.trim()"></truncate>
          </p>

          <!-- Comments Markers -->
          <div v-if="commentsToggle && adz.headerCommentMarkers && adz.headerCommentMarkers.length">
            <div
              v-for="(marker, index) in adz.headerCommentMarkers"
              :key="index"
              class="comment-marker"
              :class="{ new: !userCommentsHistory.includes(marker.id) }"
              :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
              @click.prevent.stop="handleMarkerClick($event, marker)"
            >
              <p>{{ marker.number ? marker.number : 1 }}</p>
            </div>
          </div>
        </div>
      </header>

      <div v-if="adz.type === 'single' || adz.type === 'stories'">
        <a :href="adz.content.url" class="simple-produit" target="_blank">
          <div class="loader-wrap">
            <div v-if="isSaving" class="loader">
              <div v-if="isSaving" class="progress">
                <p class="pourcentage">{{ parseInt(uploadProgress) }}<sup>%</sup></p>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" :style="'width:' + uploadProgress + '%'"></div>
                </div>
              </div>
            </div>

            <div class="markers-box-wrap">
              <video v-if="adz.content.videoRef" controls autoplay loop muted poster="/" @click.stop="handleCreateMarker($event, adz)">
                <source :src="fetchVideoThumb(adz.content.videoRef, adz.content.videoRefFromId)" type="video/mp4" />
              </video>
              <img
                v-else-if="adz.content.croppedImg"
                id="previewImgCropped"
                ref="previewImg"
                :src="adz.content.croppedImg"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />
              <img
                v-else-if="adz.content.image"
                id="previewImgOriginalFallback"
                ref="previewImg"
                :src="fetchThumb(adz.content.imageRef, adz.content.imageRefFromId, adz.content.imageRatio)"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />
              <img
                v-else-if="!adz.content.videoMp4 && !adz.content.image"
                id="adzImgPlaceholder"
                src="@/assets/img/simple-product-placeholder.svg"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />

              <!-- Comments Markers -->
              <div v-if="commentsToggle && adz.content && adz.content.commentMarkers">
                <div
                  v-for="(marker, index) in adz.content.commentMarkers"
                  :key="index"
                  class="comment-marker"
                  :class="{ new: !userCommentsHistory.includes(marker.id) }"
                  :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
                  @click.prevent.stop="handleMarkerClick($event, marker)"
                >
                  <p>{{ marker.number ? marker.number : 1 }}</p>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div class="fb-ad-footer footer-markers-box-wrap" @click="handleCreateFooterMarker($event)">
              <div class="data">
                <p v-if="adz.hasAutoDisplayLink && adz.hasAutoDisplayLink === 'yes' && adz.useAutoDisplayLink && adz.content.urlText" class="url">
                  {{ adz.content.urlText }}
                </p>

                <p v-if="!adz.hasAutoDisplayLink && adz.content.urlText" class="url">
                  {{ adz.content.urlText }}
                </p>

                <p v-if="adz.content.metaTitle" class="title">
                  {{ adz.content.metaTitle }}
                </p>

                <p v-if="adz.content.metaDesc" class="description">
                  {{ adz.content.metaDesc }}
                </p>
              </div>
              <div class="cta">
                <p v-if="adz.content.ctaSelectedLabel">{{ adz.content.ctaSelectedLabel }}</p>
                <p v-else-if="ctaOptions && ctaOptions.find((i) => i.id === adz.content.cta)">
                  {{ ctaOptions.find((i) => i.id === adz.content.cta).label_en }}
                </p>
                <p v-else-if="adz.content.cta == ''">Call to action</p>
                <p v-else>{{ adz.content.cta }}</p>
              </div>
            </div>

            <!-- Comments Markers -->
            <div v-if="commentsToggle && adz.footerCommentMarkers && adz.footerCommentMarkers.length">
              <div
                v-for="(marker, index) in adz.footerCommentMarkers"
                :key="index"
                class="comment-marker"
                :class="{ new: !userCommentsHistory.includes(marker.id) }"
                :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
                @click.prevent.stop="handleMarkerClick($event, marker)"
              >
                <p>{{ marker.number ? marker.number : 1 }}</p>
              </div>
            </div>
          </footer>
        </a>
      </div>

      <div v-if="adz.type === 'multiple'" class="carrousel-fb">
        <flickity v-if="adz.contentSlider && adz.contentSlider[0]" ref="flickity" class="main-carousel" :options="flickityOptions">
          <div v-for="n in 10" v-show="adz.contentSlider[n - 1]" :key="n" class="carousel-cell">
            <div class="markers-box-wrap">
              <img
                v-if="adz.contentSlider[n - 1] && adz.contentSlider[n - 1].image"
                :src="fetchThumb(adz.contentSlider[n - 1].imageRef, adz.contentSlider[n - 1].imageRefFromId)"
                alt="Facebook"
                @click.stop="handleCreateMarker($event, adz.contentSlider[n - 1], n - 1)"
              />
              <video
                v-else-if="adz.contentSlider[n - 1] && adz.contentSlider[n - 1].videoRef"
                controls
                autoplay
                loop
                muted
                poster="/"
                @click.stop="handleCreateMarker($event, adz.contentSlider[n - 1], n - 1)"
              >
                <source :src="fetchVideoThumb(adz.contentSlider[n - 1].videoRef, adz.contentSlider[n - 1].videoRefFromId)" type="video/mp4" />
              </video>
              <img
                v-else
                src="@/assets/img/simple-product-placeholder.svg"
                alt="Facebook"
                @click.stop="handleCreateMarker($event, adz.contentSlider[n - 1], n - 1)"
              />

              <footer @click.stop="handleCreateMarker($event, adz.contentSlider[n - 1], n - 1)">
                <div class="titles">
                  <p class="title">
                    {{ adz.contentSlider[n - 1] && adz.contentSlider[n - 1].title ? adz.contentSlider[n - 1].title : 'Title' }}
                  </p>
                  <p class="subtitle">
                    {{ adz.contentSlider[n - 1] && adz.contentSlider[n - 1].description ? adz.contentSlider[n - 1].description : '' }}
                  </p>
                </div>

                <a :href="adz.contentSlider[n - 1] && adz.contentSlider[n - 1].url ? adz.contentSlider[n - 1].url : ''" class="bt-carrousel">
                  <p v-if="adz.content.ctaSelectedLabel">{{ adz.content.ctaSelectedLabel }}</p>
                  <p v-else-if="ctaOptions && ctaOptions.find((i) => i.id === adz.content.cta)">
                    {{ ctaOptions.find((i) => i.id === adz.content.cta).label_en }}
                  </p>
                  <p v-else-if="adz.content.cta == ''">Call to action</p>
                  <p v-else>{{ adz.content.cta }}</p>
                </a>
              </footer>

              <a
                :href="adz.contentSlider[n - 1] && adz.contentSlider[n - 1].url ? adz.contentSlider[n - 1].url : ''"
                target="_blank"
                class="fb-ad-footer"
                v-if="platform !== 'facebook'"
              >
                <div class="cta">
                  <p v-if="adz.content.ctaSelectedLabel">{{ adz.content.ctaSelectedLabel }}</p>
                  <p v-else-if="ctaOptions && ctaOptions.find((i) => i.id === adz.content.cta)">
                    {{ ctaOptions.find((i) => i.id === adz.content.cta).label_en }}
                  </p>
                  <p v-else-if="adz.content.cta == ''">Call to action</p>
                  <p v-else>{{ adz.content.cta }}</p>
                </div>
              </a>

              <!-- Comments Markers -->
              <div v-if="commentsToggle && adz.contentSlider[n - 1] && adz.contentSlider[n - 1].commentMarkers">
                <div
                  v-for="(marker, index) in adz.contentSlider[n - 1].commentMarkers"
                  :key="index"
                  class="comment-marker"
                  :class="{ new: !userCommentsHistory.includes(marker.id) }"
                  :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
                  @click.prevent.stop="handleMarkerClick($event, marker)"
                >
                  <p>{{ marker.number ? marker.number : 1 }}</p>
                </div>
              </div>
            </div>
          </div>
        </flickity>
      </div>

      <img v-if="platform !== 'facebook'" src="@/assets/img/instagram/ad-footer.jpg" class="instagram-ad-footer" alt="Showadz" />
      <div v-if="platform !== 'facebook'">
        <p v-if="adz.content.text == ''" class="post-description">Texte</p>
        <p v-else-if="adz.content.text && commentsToggle" class="post-description">{{ adz.content.text.trim() }}</p>
        <p v-else-if="adz.content.text && !commentsToggle" class="post-description">
          <truncate clamp="See More" :length="126" less="Show Less" type="html" :text="adz.content.text.trim()"></truncate>
        </p>
      </div>

      <img src="@/assets/img/facebook/new-post-facebook-footer.jpg" class="post-footer desktop" alt="Facebook" />
      <img src="@/assets/img/facebook/new-mobile-post-facebook-footer.jpg" class="post-footer mobile" alt="Facebook" />
    </div>
    <div v-else class="stories-container">
      <div class="story-wrap">
        <div class="header">
          <client-logo v-if="client" :client="client" ></client-logo>
          <div class="text" v-if="client">
            <p class="name">{{ client.name }}</p>
            <p class="sponsor">Sponsored</p>
          </div>
          <div class="gradient"></div>
        </div>

        <div class="main-carousel">
          <div class="carousel-cell">
            <div class="markers-box-wrap">
              <!-- Media file -->
              <video v-if="adz.content.videoRef" controls autoplay loop muted poster="/" @click.stop="handleCreateMarker($event, adz)">
                <source :src="fetchVideoThumb(adz.content.videoRef, adz.content.videoRefFromId)" type="video/mp4" />
              </video>
              <img
                v-else-if="adz.content.croppedImg"
                id="previewImgCropped"
                ref="previewImg"
                :src="adz.content.croppedImg"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />
              <img
                v-else-if="adz.content.image"
                id="previewImgOriginalFallback"
                ref="previewImg"
                :src="fetchThumb(adz.content.imageRef, adz.content.imageRefFromId, adz.content.imageRatio)"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />
              <img
                v-else-if="!adz.content.videoMp4 && !adz.content.image"
                id="adzImgPlaceholder"
                src="@/assets/img/simple-product-placeholder.svg"
                alt="Adz"
                @click.stop="handleCreateMarker($event, adz)"
              />

              <!-- CTA -->
              <div class="footer">
                <img class="arrow" src="@/assets/img/arrow-down.svg" alt="Arrow" />
                <a :href="adz.content.url" class="cta" v-if="adz.content.cta">{{ adz.content.ctaSelectedLabel}}</a>
                <a href="#" class="cta" v-else>&nbsp;Call to action</a>
              </div>

              <!-- Comments Markers -->
              <div v-if="commentsToggle && adz.content && adz.content.commentMarkers">
                <div
                  v-for="(marker, index) in adz.content.commentMarkers"
                  :key="index"
                  class="comment-marker"
                  :class="{ new: !userCommentsHistory.includes(marker.id) }"
                  :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
                  @click.prevent.stop="handleMarkerClick($event, marker)"
                >
                  <p>{{ marker.number ? marker.number : 1 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loader / Save -->
        <div v-if="!commentsToggle" class="loader-wrap">
          <div v-if="isSaving" class="loader">
            <div v-if="isSaving" class="progress">
              <p class="pourcentage">{{ parseInt(uploadProgress) }}<sup>%</sup></p>
              <div class="progress-bar-wrap">
                <div class="progress-bar" :style="'width:' + uploadProgress + '%'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Comments thread Popup -->
    <div class="comment-thread" :class="{ active: showCommentThread }" @click.stop>
      <div v-if="commentsThread && commentsThread.length" class="thread-zone">
        <div v-for="(item, index) in commentsThread" :key="index" class="comment">
          <header>
            <div v-if="item.user" class="left">
              <div class="profile">
                <p v-if="item.user.displayName">{{ item.user.displayName[0] + item.user.displayName[1] }}</p>
                <p v-else>{{ item.user.email[0] }}</p>
              </div>
              <p class="name">{{ item.user.displayName }}</p>
            </div>
            <div v-else class="left">
              <div class="profile">
                <p>{{ client.name[0] + client.name[1] }}</p>
              </div>
              <p class="name">{{ client.name }}</p>
            </div>
            <div v-if="index === 0 && user" class="right">
              <a v-if="currentMarker && currentMarker.isHeader" class="resolve-comment" href="#" @click.prevent.stop="willResolveCurrentMarker('header')"
                >Resolve</a
              >
              <a
                v-else-if="currentMarker && currentMarker.isFooter"
                class="resolve-comment"
                href="#"
                @click.prevent.stop="willResolveCurrentMarker('footer')"
                >Resolve</a
              >
              <a v-else class="resolve-comment" href="#" @click.prevent.stop="willResolveCurrentMarker()">Resolve</a>
            </div>
          </header>
          <p class="comment-text">{{ item.content }}</p>
        </div>
      </div>
      <div class="reply-zone">
        <div class="line"></div>
        <div class="comment reply">
          <header>
            <div v-if="user" class="left">
              <div class="profile">
                <p v-if="user.displayName">{{ user.displayName[0] + user.displayName[1] }}</p>
                <p v-else>{{ user.email[0] }}</p>
              </div>
            </div>
            <div v-else class="left">
              <div class="profile">
                <p>{{ client.name.trim()[0] + client.name.trim()[1] }}</p>
              </div>
            </div>
            <div v-if="(!commentsThread || !commentsThread.length) && user" class="right">&nbsp;</div>
          </header>
          <form action="" @submit.prevent.stop="handleCommentCreation()">
            <textarea v-model="userComment" class="write-comment" placeholder="Write a comment" required></textarea>
            <input type="submit" class="send-comment" value="Post" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Flickity from 'vue-flickity'
import truncate from 'vue-truncate-collapsed'
import { getPercentFromZone, positionCommentBox } from '@/misc/helpers'

import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import CommentsThreadDB from '@/firebase/commentsThread-db'
import AdCreativeLinkDataCallToActionDB from '@/firebase/adCreativeLinkDataCallToAction-db'

import ClientLogo from '@/components/clients/ClientLogo'

export default {
  components: {
    Flickity,
    ClientLogo,
    truncate,
  },
  props: {
    adz: {
      type: Object,
      required: true,
      default: null,
    },
    client: {
      type: Object,
      required: true,
      default: null,
    },
    isSaving: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadProgress: {
      type: Number,
      required: false,
      default: 0,
    },
    showApprove: {
      type: Boolean,
      required: false,
      default: false,
    },
    accountId: {
      type: String,
      required: true,
      default: null,
    },
    clientId: {
      type: String,
      required: true,
      default: null,
    },
    campaignId: {
      type: String,
      required: true,
      default: null,
    },
    adzId: {
      type: String,
      required: true,
      default: null,
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
    refreshAdzView: {
      type: Function,
      required: false,
    },
    platform: {
      type: String,
      required: false,
      default: 'facebook',
    },
    sharedAccount: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      showCommentThread: false,
      commentsThread: null,
      currentMarker: null,
      currentMarkerAdz: null,
      markerIsEmpty: null,
      isLoading: false,
      userComment: '',
      userCommentsHistory: '',
      ctaOptions: null,
      flickityOptions: {
        imagesLoaded: true,
        cellAlign: 'center',
        groupCells: true,
        pageDots: true,
        percentPosition: false,
        contain: true,
        lazyLoad: 2,
        on: {
          change: (index) => {
            this.activetab = index + 1
          },
        },
      },
    }
  },

  computed: {
    ...mapState('users', ['account', 'selectedAdz']),
    ...mapState('app', ['commentsToggle', 'commentingAdz']),
    ...mapState('authentication', ['user']),
  },

  async mounted() {
    this.userCommentsHistory = localStorage.getItem('userCommentsHistory') ? localStorage.getItem('userCommentsHistory') : ''

    document.querySelector('body').addEventListener('click', () => {
      this.toggleCommentsPopupVisibility(false) // Force Close
    })

    const adCreativeLinkDataCallToActionDb = new AdCreativeLinkDataCallToActionDB()
    this.ctaOptions = await adCreativeLinkDataCallToActionDb.readAll(null, false, 'type')
  },

  methods: {
    async approveAdz(adz) {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)

      const approvedAdz = { ...adz, isApproved: !this.adz.isApproved }

      await accountClientCampaignAdzDbRef.update(approvedAdz)

      this.refreshAdzView()
    },
    async handleCreateMarker(e, slide, pos = false) {
      // console.log('handleCreateMarker', e)
      e.preventDefault()

      if (!this.commentsToggle) {
        if (e.target.closest('a')) {
          window.open(e.target.closest('a').getAttribute('href'))
        }
        return
      }

      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false)
        return
      }

      const target = e.target.closest('.markers-box-wrap')
      const { percentX, percentY } = getPercentFromZone(e, target)

      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)
      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)
      const markerNumber = currentAdz.markerCurrentIndex ? currentAdz.markerCurrentIndex : 1

      const newMarker = {
        number: markerNumber,
        posX: percentX,
        posY: percentY,
        id: `${new Date().getTime()}`,
        type: this.adz.type,
        isHeader: false,
        isFooter: false,
      }

      if (pos !== false && currentAdz.type === 'multiple') {
        const updatedAdz = { ...currentAdz }
        newMarker['type'] = 'carousel'

        updatedAdz['contentSlider'][pos]['commentMarkers'] = updatedAdz['contentSlider'][pos]['commentMarkers']
          ? updatedAdz['contentSlider'][pos]['commentMarkers']
          : []

        updatedAdz['contentSlider'][pos]['commentMarkers'].push(newMarker)
        updatedAdz.markerCurrentIndex = markerNumber + 1

        await accountClientCampaignAdzDbRef.update(updatedAdz)

        this.refreshAdzView()
      } else {
        const updatedAdz = { ...currentAdz }

        updatedAdz['content']['commentMarkers'] = updatedAdz['content']['commentMarkers'] ? updatedAdz['content']['commentMarkers'] : []

        updatedAdz['content']['commentMarkers'].push(newMarker)
        updatedAdz.markerCurrentIndex = markerNumber + 1

        await accountClientCampaignAdzDbRef.update(updatedAdz)

        this.refreshAdzView()
      }

      setTimeout(() => {
        this.currentMarker = newMarker
        this.currentMarkerAdz = currentAdz
        this.markerIsEmpty = newMarker
        this.handleMarkerClick({ clientX: null, clientY: null }, this.currentMarker, e.clientX, e.clientY)
      }, 15)
    },
    async handleCreateHeaderMarker(e) {
      e.preventDefault()
      // console.log(adz)

      if (!this.commentsToggle) {
        return
      }

      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false)
        return
      }

      const { percentX, percentY } = getPercentFromZone(e, e.target.closest('.header-markers-box-wrap'))

      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)
      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)
      const markerNumber = currentAdz.markerCurrentIndex ? currentAdz.markerCurrentIndex : 1

      const newMarker = {
        number: markerNumber,
        posX: percentX,
        posY: percentY,
        id: `${new Date().getTime()}`,
        type: this.adz.type,
        isHeader: true,
        isFooter: false,
      }

      const updatedAdz = { ...currentAdz }
      updatedAdz['headerCommentMarkers'] = updatedAdz['headerCommentMarkers'] ? updatedAdz['headerCommentMarkers'] : []
      updatedAdz['headerCommentMarkers'].push(newMarker)
      updatedAdz.markerCurrentIndex = markerNumber + 1

      await accountClientCampaignAdzDbRef.update(updatedAdz)

      this.refreshAdzView()

      setTimeout(() => {
        this.currentMarker = newMarker
        this.currentMarkerAdz = currentAdz
        this.markerIsEmpty = newMarker
        this.handleMarkerClick({ clientX: null, clientY: null }, this.currentMarker, e.clientX, e.clientY)
      }, 15)
    },
    async handleCreateFooterMarker(e) {
      e.preventDefault()
      // console.log(adz)

      if (!this.commentsToggle) {
        return
      }

      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false)
        return
      }

      const { percentX, percentY } = getPercentFromZone(e, e.target.closest('.footer-markers-box-wrap'))

      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)
      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)
      const markerNumber = currentAdz.markerCurrentIndex ? currentAdz.markerCurrentIndex : 1

      const newMarker = {
        number: markerNumber,
        posX: percentX,
        posY: percentY,
        id: `${new Date().getTime()}`,
        type: this.adz.type,
        isHeader: false,
        isFooter: true,
      }

      const updatedAdz = { ...currentAdz }
      updatedAdz['footerCommentMarkers'] = updatedAdz['footerCommentMarkers'] ? updatedAdz['footerCommentMarkers'] : []
      updatedAdz['footerCommentMarkers'].push(newMarker)
      updatedAdz.markerCurrentIndex = markerNumber + 1

      await accountClientCampaignAdzDbRef.update(updatedAdz)

      this.refreshAdzView()

      setTimeout(() => {
        this.currentMarker = newMarker
        this.currentMarkerAdz = currentAdz
        this.markerIsEmpty = newMarker
        this.handleMarkerClick({ clientX: null, clientY: null }, this.currentMarker, e.clientX, e.clientY)
      }, 15)
    },
    async handleMarkerClick(e, marker, usePosX = null, usePosY = null) {
      // Load marker comments thread
      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false) // Force Close
        return
      }

      this.currentMarker = marker
      this.currentMarkerAdz = this.currentMarkerAdz

      if (this.adz.type === 'stories') {
        positionCommentBox(e, this.currentMarker.posX + 155, this.currentMarker.posY + 125)
      } else {
        positionCommentBox(e, usePosX, usePosY)
      }

      this.toggleCommentsPopupVisibility(true) // Force Open
      this.refreshCurrentCommentsThread(marker)

      // User has seen this maker's commentsThrea
      const userCommentsHistory = localStorage.getItem('userCommentsHistory') ? localStorage.getItem('userCommentsHistory') : ''
      let updatedHistory = ''
      if (!userCommentsHistory) {
        updatedHistory = marker.id
      } else {
        updatedHistory = userCommentsHistory.includes(marker.id) ? userCommentsHistory : userCommentsHistory + ', ' + marker.id
      }
      localStorage.setItem('userCommentsHistory', updatedHistory)
      this.userCommentsHistory = localStorage.getItem('userCommentsHistory')
    },
    async willResolveCurrentMarker(type = 'main') {
      // console.log(this.currentMarker, this.currentMarker.id)

      this.$swal({
        title: 'Resolve',
        text: `This comment will be closed and deleted.`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/archive.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          if (type === 'header') {
            this.resolveCurrentHeaderMarker()
          } else if (type === 'footer') {
            this.resolveCurrentFooterMarker()
          } else {
            this.resolveCurrentMaker()
          }
        }
      })
    },
    async resolveCurrentMaker(removeFromCounter = false) {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)

      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)

      if (currentAdz.type === 'multiple') {
        currentAdz.contentSlider.forEach((elem) => {
          if (elem.commentMarkers && elem.commentMarkers.findIndex((m) => m.id === this.currentMarker.id) !== -1) {
            const indexOfMarker = elem.commentMarkers.findIndex((m) => m.id === this.currentMarker.id)
            elem.commentMarkers.splice(indexOfMarker, 1)
          }
        })
      } else {
        const adzContent = currentAdz.content
        const indexOfMarker = adzContent.commentMarkers.findIndex((m) => m.id === this.currentMarker.id)
        currentAdz.content.commentMarkers.splice(indexOfMarker, 1)
      }

      let counter = currentAdz.markerCurrentIndex
      if (removeFromCounter) {
        counter = counter - 1
      }
      const updatedAdz = { ...currentAdz, markerCurrentIndex: counter }

      await accountClientCampaignAdzDbRef.update(updatedAdz)

      this.refreshAdzView()

      const CommentsThreadDBRef = new CommentsThreadDB()
      await CommentsThreadDBRef.delete(this.currentMarker.id)

      this.currentMarker = null
      this.currentMarkerAdz = null
      this.commentsThread = null
      this.markerIsEmpty = null
      this.userComment = ''

      this.toggleCommentsPopupVisibility(false)
    },
    async resolveCurrentHeaderMarker(removeFromCounter = false) {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)

      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)

      currentAdz['headerCommentMarkers'] = currentAdz['headerCommentMarkers'] ? currentAdz['headerCommentMarkers'] : []
      const indexOfMarker = currentAdz['headerCommentMarkers'].findIndex((m) => m.id === this.currentMarker.id)

      if (indexOfMarker !== -1) {
        currentAdz['headerCommentMarkers'].splice(indexOfMarker, 1)

        let counter = currentAdz.markerCurrentIndex
        if (removeFromCounter) {
          counter = counter - 1
        }
        const updatedAdz = { ...currentAdz, markerCurrentIndex: counter }

        await accountClientCampaignAdzDbRef.update(updatedAdz)

        this.refreshAdzView()

        const CommentsThreadDBRef = new CommentsThreadDB()
        await CommentsThreadDBRef.delete(this.currentMarker.id)

        this.toggleCommentsPopupVisibility(false)

        this.currentMarker = null
        this.currentMarkerAdz = null
        this.commentsThread = null
        this.userComment = ''
      }
    },
    async resolveCurrentFooterMarker(removeFromCounter = false) {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)

      const currentAdz = await accountClientCampaignAdzDbRef.read(this.adz.id)

      currentAdz['footerCommentMarkers'] = currentAdz['footerCommentMarkers'] ? currentAdz['footerCommentMarkers'] : []
      const indexOfMarker = currentAdz['footerCommentMarkers'].findIndex((m) => m.id === this.currentMarker.id)

      if (indexOfMarker !== -1) {
        currentAdz['footerCommentMarkers'].splice(indexOfMarker, 1)

        let counter = currentAdz.markerCurrentIndex
        if (removeFromCounter) {
          counter = counter - 1
        }
        const updatedAdz = { ...currentAdz, markerCurrentIndex: counter }

        await accountClientCampaignAdzDbRef.update(updatedAdz)

        this.refreshAdzView()

        const CommentsThreadDBRef = new CommentsThreadDB()
        await CommentsThreadDBRef.delete(this.currentMarker.id)

        this.toggleCommentsPopupVisibility(false)

        this.currentMarker = null
        this.currentMarkerAdz = null
        this.commentsThread = null
        this.userComment = ''
      }
    },
    async handleCommentCreation() {
      if (!this.userComment) {
        return
      }

      // console.log(marker)

      const CommentsThreadDBRef = new CommentsThreadDB()
      let commentsThread = await CommentsThreadDBRef.read(this.currentMarker.id)

      if (!commentsThread) {
        commentsThread = await CommentsThreadDBRef.create({ id: this.currentMarker.id, comments: [] }, this.currentMarker.id)
      }

      const currentComments = commentsThread.comments.length ? commentsThread.comments : []

      currentComments.push({
        user: this.user,
        content: this.userComment,
      })

      commentsThread.comments = currentComments

      await CommentsThreadDBRef.update(commentsThread)

      this.refreshCurrentCommentsThread()
      this.userComment = ''
      this.markerIsEmpty = null
    },
    async refreshCurrentCommentsThread(marker = null) {
      const CommentsThreadDBRef = new CommentsThreadDB()
      const commentsThread = await CommentsThreadDBRef.read(this.currentMarker.id)
      this.commentsThread = commentsThread && commentsThread.comments.length ? commentsThread.comments : []
      if (!this.commentsThread.length) {
        this.markerIsEmpty = marker
      }
    },
    toggleCommentsPopupVisibility(forceValue = 'none') {
      // console.log('toggleCommentsPopupVisibility', this.adz.name, forceValue)
      // Always start fresh on current commentsThread
      this.commentsThread = null
      this.userComment = ''

      if (forceValue !== 'none') {
        this.showCommentThread = forceValue
        if (forceValue === false) {
          this.setCommentingAdz(null)
        }
        if (forceValue === false && this.markerIsEmpty) {
          // console.log('Should delete marker')
          this.deleteEmptyMarker()
        }
        if (forceValue === true) {
          setTimeout(() => {
            document.querySelector('.comment textarea').focus()
            this.setCommentingAdz(this.adz)
          }, 555)
        }
        return
      }

      if (this.showCommentThread) {
        this.showCommentThread = false
        this.setCommentingAdz(null)
        if (this.markerIsEmpty) {
          this.deleteEmptyMarker()
        }
      } else {
        this.showCommentThread = true
        this.setCommentingAdz(this.adz)
        // console.log('Should focus')
        setTimeout(() => {
          document.querySelector('.comment textarea').focus()
        }, 555)
      }
    },
    deleteEmptyMarker() {
      this.currentMarker = this.markerIsEmpty
      if (this.markerIsEmpty.isHeader) {
        this.resolveCurrentHeaderMarker(true)
      } else if (this.markerIsEmpty.isFooter) {
        this.resolveCurrentFooterMarker(true)
      } else {
        this.resolveCurrentMaker(true)
      }
    },
    fetchThumb(fullPath, imageFromId = '', ratio = false) {
      const adzId = imageFromId ? imageFromId : this.adz.id
      let accountId = this.account && this.account[0] ? this.account[0].id : null
      if (!accountId) {
        accountId = this.accountId
      }
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/
      // homme-fr-bgap4TchhlUIv4CI57In.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
      let thumbPath = fullPath
      if (ratio && ['1:1','1.91:1'].includes(ratio)) {
        if (ratio === '1:1') {
          thumbPath = thumbPath.replace('.jpg', '_1200x1200.jpg') // Thumbs format
          thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2Fthumbs2%2F${thumbPath}?alt=media`
        }
        if (ratio === '1.91:1') {
          thumbPath = thumbPath.replace('.jpg', '_1200x628.jpg') // 2 format
          thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2Fthumbs2%2F${thumbPath}?alt=media`
        }
      } else {
        thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      }
      return thumbPath
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/4Wo75vIGxneCEvXnlrA6xH75f8u1%2Fadz%2Fbgap4TchhlUIv4CI57In%2Fthumbs2%2Fhomme-fr-bgap4TchhlUIv4CI57In_600x600.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
    },
    fetchVideoThumb(fullPath, imageFromId = '') {
      const adzId = imageFromId ? imageFromId : this.adz.id
      let accountId = this.account && this.account[0] ? this.account[0].id : null
      if (!accountId) {
        accountId = this.accountId
      }
      // "https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/
      //  masques-XZ6LlFM3c2fgFHMcqsHK.mp4?alt=media&token=f8a6cafb-bc6c-4c8b-83a1-4359e761ff4d"
      let thumbPath = fullPath
      thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      return thumbPath
      // "https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/4Wo75vIGxneCEvXnlrA6xH75f8u1%2Fadz%2FXZ6LlFM3c2fgFHMcqsHK%2Fmasques-XZ6LlFM3c2fgFHMcqsHK.mp4?alt=media&token=f8a6cafb-bc6c-4c8b-83a1-4359e761ff4d
    },
    ...mapActions('users', ['changeAdzAprroveStatus']),
    ...mapActions('app', ['setCommentingAdz']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/comments.scss';

.facebook-post {
  width: 590px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #1d2129;
  overflow: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 1240px) {
    max-width: 550px;
  }
  @media screen and (max-width: 570px) {
    width: 100%;
    border-radius: 0;
    position: relative;
  }
}

.facebook-post-header {
  padding: 12px 16px;
}

.facebook-client {
  width: 100%;
  display: flex;
  img {
    flex-basis: 40px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.approbation-bt {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    p {
      opacity: 1;
    }
    .round {
      opacity: 1;
    }
  }

  .round {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: #fff;
    border: 2px solid $dark-grey;
    margin-left: 5px;
    opacity: 0.5;
  }

  svg path {
    fill: $dark-grey;
  }

  p {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: $dark-grey;
    opacity: 0.5;
  }

  &.active {
    p {
      opacity: 1;
    }
    .round {
      opacity: 1;
      background: linear-gradient(to right, #2bb0c5, #6bd8ba);
      border: 0;
    }
    svg path {
      fill: #fff;
    }
  }
}

.facebook-client-name {
  position: relative;
  top: 1px;
  left: 10px;
  flex-grow: 1;

  .name {
    font-size: 14px;
    font-weight: 500;
    color: #385898;
  }

  .sponsor {
    display: flex;

    img {
      flex-basis: 12px;
      width: 16px;
      height: 16px;
    }

    p {
      color: #a4a4a4;
      font-size: 12px;
      margin-right: 5px;
    }
  }
}

.post-description {
  color: #1d2129;
  font-size: 15px;
  line-height: 19px;
  margin-top: 6px;

  span {
    white-space: pre-wrap;
  }

  div div div {
    white-space: pre-wrap;
  }
}

video {
  width: 100%;
  display: block;
}

.simple-produit {
  display: block;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }

  .loader-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 9;

    .loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(31, 37, 50, 0.8);
      z-index: 9;
    }

    .progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 350px;
      text-align: center;

      .pourcentage {
        font-family: 'Open Sans', sans-serif;
        font-size: 40px;
        color: #fff;
        font-weight: 300;
        sup {
          font-size: 50%;
          font-weight: 400;
        }
      }

      .progress-bar-wrap {
        width: 100%;
        height: 8px;
        background: #fff;
        border-radius: 20px;

        .progress-bar {
          width: 0%;
          height: 8px;
          background: linear-gradient(to right, #2e7df2, #8a23f1);
        }
      }
    }

    .video-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;

      .message {
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        color: #fff;
        font-weight: 300;
      }
    }
  }

  img {
    width: 100%;
    display: block;
  }

  footer {
    position: relative;
  }
}

.fb-ad-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px;
  background-color: #f0f2f5;
  border-top: 1px solid #d7d4d4;
  border-bottom: 1px solid #d7d4d4;

  .data {
    width: 60%;
    .url {
      color: #606770;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 7px;
      padding-right: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .title {
      font-size: 16px;
      color: #1c2028;
      font-weight: 500;
      margin-bottom: 7px;
      padding-right: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .description {
      font-size: 14px;
      color: #606770;
      padding-right: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400;
    }
  }

  .cta {
    width: auto;
    background-color: #e4e6eb;
    padding: 8px 12px;
    border-radius: 2px;

    p {
      color: #050505;
      font-weight: 500;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.post-footer {
  width: 100%;
  padding: 12px 16px;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 570px) {
    padding: 0;

    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
}

.carrousel-fb {
  :hover {
    .carousel-cell {
      opacity: 0.3;

      &.is-selected {
        opacity: 1;
      }
    }
  }

  .carousel-cell {
    width: 308px;
    padding-left: 6px;
    transition: all 0.2s ease;

    img {
      width: 302px;
      height: 302px;
      border-top: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    footer {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      padding: 12px;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .titles {
        min-width: 0;
      }

      .title {
        width: 100%;
        font-size: 14px;
        color: #1c2028;
        font-weight: 500;
        padding-right: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        width: 100%;
        font-size: 11px;
        color: #65676b;
        font-weight: 400;
        padding-right: 10px;
        padding-top: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media screen and (max-width: 570px) {
          display: none;
        }
      }

      .bt-carrousel {
        width: auto;
        display: inline-block;
        background-color: #e4e6eb;
        padding: 8px 12px;
        border-radius: 2px;
        text-decoration: none;

        p {
          color: #050505;
          font-weight: 500;
          font-size: 15px;
          white-space: nowrap;
        }
      }
    }
  }

  .flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-prev-next-button {
    top: 43%;
    width: 35px;
    height: 45px;
    background-color: #fff;
    border-radius: 0;
    border-top: 2px solid #f4f4f4;
    border-left: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
  }

  .flickity-button-icon {
    fill: #f00 !important;
  }

  .flickity-prev-next-button.next {
    right: 0;
  }

  .flickity-prev-next-button.previous {
    left: 0;
  }

  .flickity-page-dots {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .flickity-button {
      display: none;
    }
  }
}

.footer-markers-box-wrap {
  width: 100%;
}

.facebook-post.is-commenting {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(233, 235, 238, 0.75);
    z-index: 55;
  }
}

/**
  Stories Adz Format
 */

.stories-container {
  // width: 590px;
  width: auto;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #1d2129;
  overflow: hidden;

  @media screen and (max-width: 1240px) {
    max-width: 550px;
  }
  @media screen and (max-width: 570px) {
    width: 100%;
    border-radius: 0;
    position: relative;
  }

  .story-wrap {
    position: relative;
    width: 360px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;

    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #fff;
      font-size: 13px;

      .name {
        font-weight: 600;
        position: relative;
        z-index: 2;
        margin-top: 20px;
      }

      .sponsor {
        position: relative;
        z-index: 2;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 20px;
        margin-left: 10px;
        position: relative;
        z-index: 2;
      }

      .gradient {
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.4;
      }
    }

    .main-carousel {
      width: 360px;
      height: 640px;

      .markers-box-wrap {
        width: 360px;
        height: 640px;
      }

      .flickity-page-dots {
        display: flex !important;
        bottom: auto;
        top: 10px;
        z-index: 100;
        padding: 0 10px;

        .dot {
          width: 100%;
          height: 2px;
          border-radius: 0;
          background-color: #fff;
          margin: 0 2px;
        }
      }
    }

    .footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 200%);
      text-align: center;
      padding-bottom: 10px;
      padding-top: 20px;

      .cta {
        z-index: 10;
        background-color: #fff;
        font-weight: 600;
        font-size: 12px;
        color: $dark-blue;
        padding: 8px 18px;
        display: inline-block;
        border-radius: 40px;
        text-transform: capitalize;
      }

      .arrow {
        transform: rotate(-180deg) translate(50%, 0);
        position: absolute;
        top: 7px;
        left: 50%;
        width: 10px;
      }
    }

    .carousel-cell {
      position: relative;
      width: 360px;
      height: 640px;
      background: #fff;

      .markers-box-wrap {
        position: relative;
        display: flex;
        flex-direction: center;
        align-items: center;
        justify-content: center;

        > img {
          width: 100%;
        }

        > video {
          width: 100%;
        }
      }
    }
  }

  .loader-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    z-index: 9;

    .loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(31, 37, 50, 0.8);
      z-index: 9;
    }

    .progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 350px;
      text-align: center;

      .pourcentage {
        font-family: 'Open Sans', sans-serif;
        font-size: 40px;
        color: #fff;
        font-weight: 300;
        sup {
          font-size: 50%;
          font-weight: 400;
        }
      }

      .progress-bar-wrap {
        width: 100%;
        height: 8px;
        background: #fff;
        border-radius: 20px;

        .progress-bar {
          width: 0%;
          height: 8px;
          background: linear-gradient(to right, #2e7df2, #8a23f1);
        }
      }
    }

    .video-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;

      .message {
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        color: #fff;
        font-weight: 300;
      }
    }
  }
}
</style>
